import React, { useState, useEffect } from 'react';
import './Target.css';
import Button from "../../Components/Button";
import axios from 'axios';
import ChartComponent from '../../Components/ChartComponent';
import Loader from 'react-loader-spinner';
import BackLink from "../../Components/BackLink";

function Target(props) {
  function savePrev() {
    var history = sessionStorage.getItem("history");
    if (!history) {
      history = {}
    }
    else {
      history = JSON.parse(history)
    }
    if (history[props.data.nextPage] === undefined) {
      history[props.data.nextPage] = props.data.name
      sessionStorage.setItem("history", JSON.stringify(history))
    }
  }
  
  const [targetLevel, setTargetLevel] = useState();
  useEffect(() => {
    let mounted = true;
    // set up for posting for target level
    var targetJson = { "question": [], "statement": [] }
    var i, j;
    for (i = 1; i <= sessionStorage.getItem("num-of-questions"); i++) {
      targetJson.question.push({ "question-id": "target-ques-" + i.toString(), "value": sessionStorage.getItem("target-ques-" + i.toString()) })
    }
    for (j = 1; j <= sessionStorage.getItem("num-of-questions"); j++) {
      targetJson.statement.push({ "question-id": "target-state-" + j.toString(), "value": sessionStorage.getItem("target-state-" + j.toString()) })
    }
    // post user input and obtain target level
    axios.post('/api/v1.0/MaturityAssessment/SubmitForTarget', targetJson)
      .then(function (response) {
        if (mounted) {
          setTargetLevel(parseInt(response.data.targetChoice));
          sessionStorage.setItem("targetLevel", parseInt(response.data.targetChoice))
          sessionStorage.setItem("pieNumber", response.data.pieNumber)
        }
      })
      .catch(function (error) {
        // handle error
        console.log(error.response);
      })
    return () => mounted = false;
  }, []);

  return (
    <div className="container">
      {targetLevel ?
        props.data && targetLevel && <div>
          <div className="col-md-12 intro-title">
            <div className="wrap">
              <div className="prev">
                <BackLink to={"/" + JSON.parse(sessionStorage.getItem("history"))[props.data.name]} />
              </div>
            </div>
            <h2>{props.data.title}</h2>
          </div>
          <div className="flex-container">
            <div className="row chart-desc">
              <div className="col-sm-6">
                <div id="report-chart">
                  <ChartComponent type={props.data.type} targetLevel={targetLevel} pieNumber={props.data.pieNumber} />
                </div>
              </div>
              <div className="col-sm-6 target-expl">
                <p className="">{props.data.content}</p>
              </div>
            </div>
            <div className="col-md-12" id="button-next">
              <Button onClick={savePrev} to={props.data.nextPage} text="Next Page" />
            </div>
          </div>
        </div> :
        <Loader className="loader" type="Oval" color="#0F204B" height={100} width={100} />
      }
    </div>
  )
};

export default Target;