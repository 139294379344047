import React, { useState } from 'react';
import './Config.css';
import Button from "../../Components/Button";
import axios from 'axios';
import Loader from 'react-loader-spinner';

function Config(props) {
  const [loading, setLoading] = useState();
  const [desc, setDesc] = useState();
  const [errList, setErrList] = useState();

  function loadConfig() {
    setLoading(true);
    axios.get('/api/v1.0/Config/LoadConfig')
      .then(function (response) {
        setLoading(false);
        if (response.data.validation) {
          setDesc("Load configuration successfully.");
          setErrList(null);
        }
        else {
          setDesc("Load configuration failed with below errors:");
          setErrList(response.data.errList);
        }
      })
      .catch(function (error) {
        // handle error
        console.log(error.response);
      })
  }

  return (
    <div className="container">
      {loading ?
        <Loader className="loader" type="Oval" color="#0F204B" height={100} width={100} /> :
        <div>
          <div className="col-md-12 tl-title">
            <h2>Load new configuration</h2>
          </div>
          <div className="flex-container">
            <div className="row desc">
              {desc ? <div className="col-sm-12 result-desc">{desc}</div> : null}
              {errList ?
                <div className="col-sm-12 result-err">
                  <ul>
                    {errList.map((value, index) => {
                      return <li key={index}>{value}</li>
                    })}
                  </ul>
                </div>
              : null}
            </div>
            <div className="col-md-12" id="button">
              <Button onClick={loadConfig} to="/config" text="Load Config" />
            </div>
          </div>
        </div>
      }
    </div>
  );
}

export default Config;