export function checkTimeout(response)
{
    if (typeof (response.data) === 'string')
    {
        console.log(response.data);
        if (response.data.includes('login.microsoftonline.com'))
        {
            window.location.href = '/';
        }
    }
}