import React, { useState } from 'react';
import './Question.css';
import Button from "../../Components/Button";
import SingleQn from "../../Components/SingleQn";
import SinglePieChart from "../../Components/SinglePieChart";
import BackLink from "../../Components/BackLink";

function Question(props) {
  if (props.data.type === "statement") {
    sessionStorage.setItem("num-of-questions", props.data.questions.length)
  }

  const [buttonItem, setButtonItem] = useState(false);
  function savePrev() {
    var history = sessionStorage.getItem("history");
    if (!history) {
      history = {}
    }
    else {
      history = JSON.parse(history)
    }
    if (history[props.data.nextPage] === undefined) {
      history[props.data.nextPage] = props.data.name
      sessionStorage.setItem("history", JSON.stringify(history))
    }
  }

  function handleSelect(value) {
    setButtonItem(value);
  }

  return (
    <div className="container">
      {props.data && <div>
        <div className="col-md-12 intro-title">
          <div className="wrap">
            <div className="prev">
              <BackLink to={"/" + JSON.parse(sessionStorage.getItem("history"))[props.data.name]} text={props.pageText["link.back"]} />
            </div>
          </div>
          <div className="title">
            <h2>{props.data.title}</h2>
          </div>
        </div>
        {props.data.questions && props.data.questions[0].mode === "single" ?
          <SingleQn onSelectItem={handleSelect} data={props.data} /> : props.data.questions && props.data.questions[0].mode === "single-piechart" ?
            <SinglePieChart data={props.data} pieNumber={props.data.questions[0].pieNumber} /> : null}
        <div key={buttonItem} className="col-md-12" id="button-next">
          {props.data.questions && props.data.questions[0].mode === "single" ? 
          ((props.data.type === "question") ? ((sessionStorage.getItem("initial-questions") === "false") ? (<input className="btn btn-center btn-primary" type="button" value={props.pageText["button.next.page"]} disabled />) : (props.data.nextPage && <Button onClick={savePrev} to={props.data.nextPage} text={props.pageText["button.next.page"]} />)) : 
          ((sessionStorage.getItem("initial-statements") === "false") ? (<input className="btn btn-center btn-primary" type="button" value={props.pageText["button.next.page"]} disabled />) : (props.data.nextPage && <Button onClick={savePrev} to={props.data.nextPage} text={props.pageText["button.next.page"]} />))) : 
          (props.data.nextPage && props.data.questions && <Button onClick={savePrev} to={props.data.nextPage} text={(props.data.questions[0].pieNumber === props.data.questions[0].piePosition.toString()) ? props.pageText["button.next.page"] : props.pageText["button.next.question"]} />)}
        </div>
      </div>}
    </div>
  )
};

export default Question;