import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../Header';
import Footer from '../Footer';
import './AuthenticatedApp.css';
import { Switch, Route, Redirect } from 'react-router-dom';
import Parent from '../../Views/Parent';
import Config from '../../Views/Config';
import Loader from 'react-loader-spinner';

function AuthenticatedApp(props) {
  const [validateURL, setValidateURL] = useState();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    axios.get('/api/v1.0/Veracity/ValidatePolicy')
      .then((response) => {
        setLoading(false);
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          window.location.href = '/account/SignOut';
        }
        else if (error.response.status === 406) {
          setValidateURL(error.response.data);
        }
        else {
          console.log(error.response);
        }
      });
  }, []);

  return (
    <div>
      {validateURL ?
        <Route path='/' component={() => {
          window.location.href = validateURL;
          return null;
        }} /> :
        loading ?
          <div className="nothing-display">
            <Loader
              type="Oval"
              color="#0F204B"
              height={100}
              width={100}
            />
          </div> :
          <div className="wrapper">
            <Header className="header-layer" />
            <Switch>
              <Route path="/config">
                <Config />
              </Route>
              <Route exact path="/">
                <Redirect to="/intro-page" />
              </Route>
              <Route path="/:slug" children={<Parent pageText={props.pageText} />} />
            </Switch>
            <Footer className="footer" />
          </div>
      }
    </div>
  );
}

export default AuthenticatedApp;