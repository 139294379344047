import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Header.css';
import Dropdown from 'react-bootstrap/Dropdown';
import { NavLink } from "react-router-dom";
import { AuthConsumer } from '../../Context/AuthContext';

function Header() {
    const [initials, setInitials] = useState("");
    const [linkLoginText, setLinkLoginText] = useState();
    const [linkLogoutText, setLinkLogoutText] = useState();
    const [linkSettingsText, setLinkSettingsText] = useState();
    const [langList, setLangList] = useState(); // list of lang codes to display in dropdown
    const [langDict, setLangDict] = useState(); // dictionary, key-value code-name to get name value of lang code
    const [currentLang, setCurrentLang] = useState("Loading...");
    const [appName, setAppName] = useState();

    useEffect(() => {
        axios.get('/account/UserName', { withCredentials: true })
            .then(function (response) {
                var res = response.data.split(",");
                if (res.length === 2) {
                    setInitials(res[1].trim().charAt(0).toUpperCase() + res[0].trim().charAt(0).toUpperCase());
                }
            })
            .catch(function (error) {
                // handle error
                console.log(error.response);
            })

        axios.post('/api/v1.0/Config/GetPageText',
            {
                "app.name": "",
                "link.login": "",
                "link.logout": "",
                "link.settings": ""
            }
        ).then(function (response) {
            setAppName(response.data["app.name"])
            setLinkLoginText(response.data["link.login"])
            setLinkLogoutText(response.data["link.logout"])
            setLinkSettingsText(response.data["link.settings"])
        })

        function createLangList(data) {
            var langs = []
            Object.keys(data).forEach((key) => {
                langs.push(key);
            })
            setLangList(langs);
        }

        axios.get('/api/v1.0/Config/GetLangList')
            .then(function (response) {
                setLangDict(response.data);
                createLangList(response.data);
                document.cookie.split(';').forEach((cookieItem) => {
                    if (cookieItem.trim().startsWith('lang=')) {
                        setCurrentLang(response.data[cookieItem.split('=')[1]])
                    }
                })
            })
    }, []);

    function langItemClick(lang) {
        setCurrentLang(langDict[lang]);
        document.cookie = "lang=" + lang + ""
        window.location.reload(false);
    }

    return (
        <header className="o-header">
            <AuthConsumer>
                {({ isSignedIn }) => (
                    <div width="100%">
                        <nav className="o-header-nav header-position">
                            <NavLink className="o-header-link" to="/" exact>
                                <img className="logo-image" src="/assets/images/logo/dnv_logo_rgb.svg" alt="DNV Logo" />
                            </NavLink>
                        </nav>
                        <div>
                            {isSignedIn ? (
                                <div className="o-header-right">
                                    <Dropdown id="lang-dropdown" title="English" size="sm" drop="down" className="lang-dropdown">
                                        {<Dropdown.Toggle>{currentLang}</Dropdown.Toggle>}
                                        <Dropdown.Menu>
                                            {langList && langList.map(lang => (
                                                <Dropdown.Item key={lang} onClick={() => { langItemClick(lang) }}>{langDict[lang]}</Dropdown.Item>
                                            ))}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <a href="/" className="o-header-product">
                                        {appName ? appName : "Self-Assessment"}
                                    </a>
                                    <ul className="o-header-actions">
                                        <li className="o-header-dropdown o-header-dropdown-user">
                                            <div className="o-header-user">
                                                <Dropdown>
                                                    <Dropdown.Toggle className="o-header-user">
                                                        {initials}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu alignRight={true}>
                                                        <Dropdown.Item href="https://services.veracity.com/EditProfile">{linkSettingsText ? linkSettingsText : "Settings"}</Dropdown.Item>
                                                        <Dropdown.Item href="/account/SignOut">{linkLogoutText ? linkLogoutText : "Logout"}</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            ) : (
                                <div className="o-header-right">
                                    <a href="/" className="o-header-product">
                                        {appName ? appName : "Self-Assessment"}
                                    </a>
                                    <ul className="o-header-actions">
                                        <li className="o-header-action">
                                            <a target="_self" href="/account/SignIn" className="o-header-action-btn">
                                                {linkLoginText ? linkLoginText : "Login"}
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </AuthConsumer>
        </header>
    )
}

export default Header;