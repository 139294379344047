import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Parent.css';
import Intro from '../Intro';
import Question from '../Question';
import Target from '../Target';
import Report from '../Report';
import { useParams } from 'react-router-dom';
import { checkTimeout } from '../../Helper';

function Parent(props) {
  let { slug } = useParams();
  const [data, setData] = useState();
  const [questionsFlag, setQuestionsFlag] = useState(false);
  const [type, setType] = useState("introduction");

  useEffect(() => {
    axios.get('/api/v1.0/MaturityAssessment/GetPage?pageName=' + slug, { withCredentials: true })
      .then(function (response) {
        checkTimeout(response);
        setData(response.data);
        if (response.data.questions != null) {
          setQuestionsFlag(true);
        }
        else {
          setQuestionsFlag(false);
          setType(response.data.type);
        }
      })
      .catch(function (error) {
        console.log(error.response);
      })
  }, [slug])

  return (
    <div>
      <div>
        {data && questionsFlag ?
          <Question data={data} pageText={props.pageText} /> :
          type === "target" ?
            <Target data={data} pageText={props.pageText} /> :
            type === "report" ?
              <Report data={data} pageText={props.pageText} /> :
              type === "introduction" ?
                <Intro data={data} pageText={props.pageText} /> : null}
      </div>
    </div>
  )
};

export default Parent;