import React, { useState, useEffect } from 'react';
import axios from 'axios';

const AuthContext = React.createContext();

function AuthProvider(props) {
  const [isSignedIn, setIsSignedIn] = useState();

  useEffect(() => {
    axios.get('/account/IsAuthenticated', { withCredentials: true })
      .then(function (response) {
        setIsSignedIn(response.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error.response);
      })
  }, []);

  return (
    <AuthContext.Provider value={{ isSignedIn }}>
      {props.children}
    </AuthContext.Provider>
  )
}

const AuthConsumer = AuthContext.Consumer;

export { AuthProvider, AuthConsumer };