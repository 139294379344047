import React, { useState } from 'react';
import './SinglePieChart.css';
import ChartComponent from '../ChartComponent';
import RadioComponent from '../RadioComponent';

function SinglePieChart(props) {
    const [rerender, setRerender] = useState(true);
    const data01 = []
    for (let i = 0; i < props.pieNumber; i++) {
        data01.push({ "name": "q" + (i + 1).toString(), "value": 1 });
        if (sessionStorage.getItem("q" + (i + 1).toString()) > 0) { }
        else {
            sessionStorage.setItem("q" + (i + 1).toString(), 1);
        }
    }

    function handleSelection(value) {
        sessionStorage.setItem('q' + (props.data.questions[0].piePosition).toString(), value)
        setRerender(!rerender);
    }

    return (
        <div className="container">
            {props.data && props.data.questions && <React.Fragment key={rerender}>
                <div className="row">
                    <div className="col-xl-6">
                        <ChartComponent data={props.data} type={props.data.type} pieNumber={props.data.questions[0].pieNumber} currentQnNumber={props.data.questions[0].piePosition} />
                    </div>
                    <div className="col-xl-6 singlepiqn">
                        {props.data.questions[0].description}<br /><br />
                        <RadioComponent data={props.data.questions[0].options} currentQnNumber={props.data.questions[0].piePosition} onSelection={handleSelection} />
                    </div>
                </div>
            </React.Fragment>}
        </div>
    )
}

export default SinglePieChart;