import React, { useState } from 'react';
import './SingleQn.css';

function SingleQn(props) {
    const [state, setState] = useState(true);
    function handleSelection(id, value) {
        sessionStorage.setItem(id, value)
        checkFilledStatus()
        setState(!state);
    }
    function checkFilledStatus() {
        var i;
        if (props.data.type === "question") { // initial-question
            sessionStorage.setItem("initial-questions", true)
            for (i = 1; i <= props.data.questions.length; i++) {
                if (sessionStorage.getItem("target-ques-" + i.toString()) == null) {
                    sessionStorage.setItem("initial-questions", false);
                    break;
                }
            }
            if (sessionStorage.getItem("initial-questions") === "true" ) {
                props.onSelectItem(true);
            }
            else {
                props.onSelectItem(false);
            }
        }
        else {
            if (props.data.type === "statement") { // initial-statement
                sessionStorage.setItem("initial-statements", true)
                for (i = 1; i <= props.data.questions.length; i++) {
                    if (sessionStorage.getItem("target-state-" + i.toString()) == null) {
                        sessionStorage.setItem("initial-statements", false);
                        break;
                    }
                }
                if (sessionStorage.getItem("initial-statements") === "true" ) {
                    props.onSelectItem(true);
                }
                else {
                    props.onSelectItem(false);
                }
            }
        }
    }

    return (
        <div className="container">
            {props.data && <div>
                <div key={state}>
                    {props.data.questions.map((item, index) => (
                        <div key={index} className="single-qn">
                            <p>{item.description}</p>
                            {item.options.map((choice, choiceIndex) => (
                                <React.Fragment key={choiceIndex}>
                                    <input key={item.id + "-radio"} type="radio" name={item.id} id={item.id + choiceIndex} value={choice.value}
                                        checked={(sessionStorage.getItem(item.id) === choice.value) ? true : false}
                                        onChange={() => { handleSelection(item.id, choice.value) }} />&nbsp;
                                    <label key={item.id + "-label"} htmlFor={item.id + choiceIndex}>{choice.text}</label>&emsp;
                                </React.Fragment>
                            ))}
                        </div>
                    ))}
                </div>
            </div>}
        </div>
    )
}

export default SingleQn;
