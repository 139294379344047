import React, { useState, useEffect } from 'react';
import './Report.css';
import axios from 'axios';
import ChartComponent from '../../Components/ChartComponent';
import Loader from 'react-loader-spinner';
import BackLink from "../../Components/BackLink";
import { checkTimeout } from '../../Helper';

function Report(props) {
  const [report, setReport] = useState();
  const [targetLevel, setTargetLevel] = useState();
  
  useEffect(() => {
    let mounted = true;
    
    // set up for posting for report
    var reportJson = { "target": {"question" : [], "statement": []}, "capability": [] }
    var i, j, k;
    for (i = 1; i <= sessionStorage.getItem("num-of-questions"); i++) {
      reportJson.target.question.push({ "question-id": "target-ques-" + i.toString(), "value": sessionStorage.getItem("target-ques-" + i.toString()) })
    }
    for (j = 1; j <= sessionStorage.getItem("num-of-questions"); j++) {
      reportJson.target.statement.push({ "question-id": "target-state-" + j.toString(), "value": sessionStorage.getItem("target-state-" + j.toString()) })
    }
    for (k = 1; k <= props.data.pieNumber; k++) {
      reportJson.capability.push({ "question-id": "capability-ques-" + k.toString(), "choice": sessionStorage.getItem("q" + k.toString()) })
    }

    // post user input and obtain target level
    axios.post('/api/v1.0/MaturityAssessment/SubmitForReport', reportJson)
      .then(function (response) {
        checkTimeout(response);
        if (mounted) {
          setReport(response.data);
          setTargetLevel(parseInt(response.data.targetChoice))
        }
      })
      .catch(function (error) {
        // handle error
        console.log(error.response);
      })
    return () => mounted = false;
  }, [props.data.pieNumber]);

  return (
    <div className="container">
      {report ? 
        props.data && <div>
          <div className="col-md-12 intro-title">
            <div className="wrap">
              <div className="prev">
              {JSON.parse(sessionStorage.getItem("history"))[props.data.name] && <BackLink to={"/" + JSON.parse(sessionStorage.getItem("history"))[props.data.name]} text={props.pageText["link.back"]} />} 
              </div>
            </div>
            <h2>{props.data.title}</h2>
          </div>
          <div className="">
            <div className="col-sm-12">
              <div id="report-chart">
                <ChartComponent data={report} type={props.data.type} targetLevel={targetLevel} pieNumber={props.data.pieNumber} pageText={props.pageText} />
              </div>
            </div>
          </div>
        </div> :
        <div className="loader">
          <Loader className="nothing-display"
            type="Oval"
            color="#0F204B"
            height={100}
            width={100}
          />
        </div>}
    </div>
  )
};

export default Report;