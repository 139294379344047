import React from 'react';
import './BackLink.css';
import {Link} from 'react-router-dom';

function BackLink(props) {
    return (
        <Link className="prev" to={props.to}>{props.text}</Link>
    )
}

export default BackLink;