import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Footer.css';

function Footer(props) {
    const [linkText, setLinkText] = useState();
    const [labelText, setLabelText] = useState();
    useEffect(() => {
        axios.post('/api/v1.0/Config/GetPageText',
            {
                "link.support": "",
                "link.support.label": ""
            }
        ).then(function (response) {
            setLinkText(response.data["link.support"])
            setLabelText(response.data["link.support.label"])
        })
    }, [])

    return (
        <footer role="contentinfo" className="footer small footer-position">
            <div className="footer-small container-fluid footer-margin">
                <ul className="footer-small-links">
                    <li>{labelText ? labelText : "Do you need help?"}</li>
                    <li>
                        <a
                            target="_blank"
                            href="https://support.veracity.com"
                            className=""
                        >
                            <u>{linkText ? linkText : "Find support here"}</u>
                        </a>
                    </li>
                </ul>
                <div className="footer-small-dnvgl">
                    <div className="my-3 mr-3 my-sm-0 mr-sm-0 title-spacing">
                        © DNV AS 2020
                </div>
                    <span className="mx-3 d-none d-sm-inline">
                        |
                </span>
                    <ul className="footer-some">
                        <li className="mr-2">
                            <a
                                target="_blank"
                                href="https://www.linkedin.com/showcase/dnv-digital/"
                                className="some-circle small"
                            >
                                <i className="fab fa-linkedin-in"></i>
                            </a>
                        </li>
                        <li className="mr-2">
                            <a
                                target="_blank"
                                href="https://twitter.com/DNV_S"
                                className="some-circle small"
                            >
                                <i className="fab fa-twitter"></i>
                            </a>
                        </li>
                        <li className="mr-2">
                            <a
                                target="_blank"
                                href="https://www.facebook.com/dnvsoftware/"
                                className="some-circle small"
                            >
                                <i className="fab fa-facebook"></i>
                            </a>
                        </li>
                        <li>
                            <a
                                target="_blank"
                                href="https://www.youtube.com/user/dnvsoftware"
                                className="some-circle small"
                            >
                                <i className="fab fa-youtube"></i>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </footer>
    )
}

export default Footer;