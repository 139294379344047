import React, { useState } from 'react';
import './RadioComponent.css';

function RadioComponent(props) {
    const [state, setState] = useState(true);

    function onSelect(value) {
        props.onSelection(value);
        setState(!state);
    }
    
    return (
        <div key={state}>
            {props.data.map((item, index) => (
                <div className="parent" key={index}>
                    <label className="label-child" htmlFor={props.currentQnNumber + index}>
                        <input type="radio" name={"radio-q" + (props.currentQnNumber).toString()} id={props.currentQnNumber + index} onChange={() => onSelect(item.choice)}
                        checked={(sessionStorage.getItem('q' + (props.currentQnNumber).toString()) === (index + 1).toString()) ? true : false} />&nbsp;
                        {item.text}
                    </label><br />
                </div>
            ))}
        </div>
    )
}

export default RadioComponent;