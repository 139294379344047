import React from 'react';
import './Intro.css';
import Button from "../../Components/Button";

function Intro(props) {
  if (sessionStorage.getItem("initial-questions") == null) {
    sessionStorage.setItem("initial-questions", false)
  }
  if (sessionStorage.getItem("initial-statements") == null) {
    sessionStorage.setItem("initial-statements", false)
  }

  function savePrev() {
    var history = sessionStorage.getItem("history");
    if (!history) {
      history = {}
    }
    else {
      history = JSON.parse(history)
    }
    if (history[props.data.nextPage] === undefined) {
      history[props.data.nextPage] = props.data.name
      sessionStorage.setItem("history", JSON.stringify(history))
    }
  };

  return (
    <div className="container">
      {props.data && <div>
        <div className="col-md-12 intro-title">
          <h2>{props.data.title}</h2>
        </div>
        <div dangerouslySetInnerHTML={{ __html: props.data.content }} />
        {props.data.nextPage && <div className="col-md-12">
          <Button onClick={savePrev} to={props.data.nextPage} text={props.pageText["button.next.page"]} />
        </div>}
      </div>}
    </div>
  )
};

export default Intro;