import React from 'react';
import './Button.css';
import {Link} from 'react-router-dom';

function Button(props) {
    return (
        <Link className="btn btn-primary btn-center" onClick={props.onClick} to={props.to}>{props.text}</Link>
    )
}

export default Button;